import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/dev.module.css"
import Video from "../components/video"
import Html from "../../static/images/html.png"
import Css from "../../static/images/css.png"
import Js from "../../static/images/js.png"
import ReactLogo from "../../static/images/physics.png"
import NodeLogo from "../../static/images/nodejs.png"
import PhpLogo from "../../static/images/php.png"
import MysqlLogo from "../../static/images/mysql.png"
import GatsbyLogo from "../../static/images/gatsbyjs.png"

export default function Dev() {
  return (
    <Layout>
      <div className={styles.devIcons}>
        <img src={Html} alt="html" height="60px" width="auto"></img>
        <img src={Css} alt="css" height="60px" width="auto"></img>
        <img src={Js} alt="js" height="60px" width="auto"></img>
        <img src={ReactLogo} alt="js" height="60px" width="auto"></img>
        <img src={GatsbyLogo} alt="js" height="60px" width="auto"></img>
        <img src={NodeLogo} alt="js" height="60px" width="auto"></img>
        <img src={PhpLogo} alt="js" height="60px" width="auto"></img>
        <img src={MysqlLogo} alt="js" height="60px" width="auto"></img>
      </div>
      <section className={styles.header}>
        <div className={styles.mainText}>
          <h2>Interactive Tool To Help </h2>
          <h2>Choose Company Goals</h2>
          <p>
            An interactive web tool to help employees choose company goals to
            focus on. This was created with HTML, CSS, and JavaScript.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/711949838"
              videoTitle="CVS Tool Demo"
              videoWidth="100%"
              videoHeight="400px"
              videoMinWidth="400px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>VoiSpark v4</h2>
          <h2>A ReactJS Web-App</h2>
          <p>
            A rebuilt web-app for managing voice talent's work order and pay.
            This was created with ReactJS, NodeJS, and SQL for DB. Box cloud
            storage and Workfront API integration. Material UI for front-end UI
            and Icons.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/712203475"
              videoTitle="VoiSpark v4 ReactJS"
              videoWidth="100%"
              videoHeight="400px"
              videoMinWidth="400px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>Interactive Survey</h2>
          <h2>HTML5, CSS3, JS</h2>
          <p>
            An interactive survey demo created for sales. Created as a single
            file HTML with CSS/JS included.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/712178719"
              videoTitle="VoiSpark v4 ReactJS"
              videoWidth="100%"
              videoHeight="400px"
              videoMinWidth="400px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>VoiSpark v3</h2>
          <h2>PHP, SQL, CURL, API</h2>
          <p>
            Web application for managing voice talent's work order and pay.
            Built with PHP, SQL, CURL, with Box cloud storage and Workfront API
            integration.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/712182355"
              videoTitle="VoiSpark v4 ReactJS"
              videoWidth="100%"
              videoHeight="400px"
              videoMinWidth="400px"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}
