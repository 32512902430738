import React from "react"
import * as styles from "../styles/video.module.css"

const Video = ({
  videoSrcURL,
  videoTitle,
  videoWidth,
  videoHeight,
  videoMinWidth,
  ...props
}) => (
  <div className={styles.video}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width={videoWidth}
      height={videoHeight}
      minWidth={videoMinWidth}
    />
  </div>
)
export default Video
